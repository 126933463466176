import {
  AssetCategoryModel,
  AssetCategoryService,
  AssetService,
  IAssetCategoryModel,
  IAssetCollectionModel,
  IUserModel,
  TimeHelper,
  useDataLoader,
} from "@bms/common-services";
import {
  Button,
  Choose,
  ChooseOption,
  Col,
  Form,
  DatePicker,
  Icon,
  IFormValues,
  Link,
  RadioGroup,
  Row,
  Spin,
  Switch,
  useSendable,
  useSyncedState,
  useValidate,
} from "@bms/common-ui";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserBrowserModal } from "../../../User/components/UserBrowserModal";
import { ROUTES as USER_ROUTES } from "../../../User/constants";
import { formLayouts, formValues } from "./AssetCollectionFiltersFormUtils";
import { Tooltip } from "antd";
import { LazyChoose } from "../../../../components";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";

const assetCategoryService = new AssetCategoryService().promisify();
const assetService = new AssetService().promisify();

export interface IAssetCollectionFiltersFormProps {
  assetCollection?: IAssetCollectionModel;
  onSubmit: (collection: IAssetCollectionModel) => Promise<boolean>;
}

interface ISortDirectionInputProps {
  value?: "ASC" | "DESC";
  onChange?: (value?: "ASC" | "DESC") => void;
}

const SortDirectionInput: React.FC<ISortDirectionInputProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <Tooltip overlay={t("FILTERS_FORM_SORT_DIRECTION")}>
      <Button
        icon={
          value === "ASC" ? (
            <SortAscendingOutlined />
          ) : (
            <SortDescendingOutlined />
          )
        }
        onClick={() => onChange?.(value === "ASC" ? "DESC" : "ASC")}
      />
    </Tooltip>
  );
};

export const AssetCollectionFiltersForm: React.FC<IAssetCollectionFiltersFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { assetCollection, onSubmit } = props;
  const [userBrowserVisible, setUserBrowserVisible] = useState(false);
  const [assetCategories, setAssetCategories] = useState([]);
  const [userCreator, setUserCreator] = useSyncedState<{
    Id?: number;
    FullName?: string | number;
  }>(
    () => ({
      Id: assetCollection?.FilterCreatorUserId,
      FullName:
        assetCollection?.FilterCreatorUserFullName ||
        assetCollection?.FilterCreatorUserId,
    }),
    [assetCollection]
  );
  const { isBefore, isAfter } = useValidate();

  const assetTypesLoader = useDataLoader({
    loader: () => assetService.getAssetTypes(),
    deps: [],
  });

  const assetTypes = useMemo(() => assetTypesLoader.data ?? [], [
    assetTypesLoader.data,
  ]);

  useEffect(() => {
    assetCategoryService
      .search({
        PageNumber: 1,
        PageSize: 100,
      })
      .then((res: any) => setAssetCategories(res.data.Entities));
  }, []);

  const radioFieldNames = [
    "FilterMyFavourites",
    "FilterRecentlyWatched",
    "FilterPurchased",
  ];

  const [radioValues, setRadioValues] = useState([
    assetCollection?.FilterMyFavourites !== undefined
      ? assetCollection?.FilterMyFavourites
      : false,
    assetCollection?.FilterRecentlyWatched !== undefined
      ? assetCollection?.FilterRecentlyWatched
      : false,
    assetCollection?.FilterPurchased !== undefined
      ? assetCollection?.FilterPurchased
      : false,
  ]);

  const changeRadioValues = (index: number, value: boolean) => {
    const temp = radioValues.map((v) => false);
    temp[index] = value;
    let newRadioValue: any = {};
    for (let i = 0; i < temp.length; i++) {
      newRadioValue[radioFieldNames[i]] = temp[i];
    }
    form.setFieldsValue(newRadioValue);
    setRadioValues(temp);
  };

  useEffect(() => {
    const temp = [
      assetCollection?.FilterMyFavourites !== undefined
        ? assetCollection?.FilterMyFavourites
        : false,
      assetCollection?.FilterRecentlyWatched !== undefined
        ? assetCollection?.FilterRecentlyWatched
        : false,
      assetCollection?.FilterPurchased !== undefined
        ? assetCollection?.FilterPurchased
        : false,
    ];
    setRadioValues(temp);
  }, [assetCollection]);

  const sendable = useSendable();

  const onFinish = async (values: IFormValues) => {
    const payload: IAssetCollectionModel = {
      ...assetCollection,
      Id: assetCollection?.Id ?? -1,
      FilterCreatorUserId: userCreator.Id,
      Categories: values.Categories,
      FilterIsPremium: values.FilterIsPremium
        ? values.FilterIsPremium === "PREMIUM"
        : undefined,
      FilterMyFavourites: values.FilterMyFavourites ? true : undefined,
      FilterRecentlyWatched: values.FilterRecentlyWatched ? true : undefined,
      FilterPurchased: values.FilterPurchased ? true : undefined,
      FilterTypes: values.MediaType.join(";"),
      FilterFutureStart: values.FilterTime === "UPCOMING",
      FilterRecentlyFinished: values.FilterTime === "PAST",
      FilterOngoingNow: values.FilterTime === "ONGOING",
      FilterYearFrom: values.FilterYearFrom
        ? TimeHelper.getDatePart(values.FilterYearFrom, "year")
        : undefined,
      FilterYearTo: values.FilterYearTo
        ? TimeHelper.getDatePart(values.FilterYearTo, "year")
        : undefined,
    };

    if (values.SortBy) {
      payload.SortByExpression = values.SortBy;

      if (values.SortDirection) {
        payload.SortByExpression += ` ${values.SortDirection}`;
      }
    } else {
      payload.SortByExpression = undefined;
    }
    const ok = await onSubmit(payload);
    if (ok) {
      sendable.resetSendable();
    }
  };

  const onUserSelect = (row: IUserModel) => {
    setUserCreator({ Id: row.Id, FullName: row.FullName || row.Id });
    setUserBrowserVisible(false);
    sendable.setDirty();
  };

  const onUserClear = () => {
    setUserCreator({ Id: undefined, FullName: undefined });
  };

  const renderCreatorField = () => {
    let filterCreatorUserView = userCreator.Id ? (
      <Link
        to={`${USER_ROUTES.USER_DETAILS}/${userCreator.Id}`}
        style={{ color: "white" }}
      >
        {userCreator.FullName}
      </Link>
    ) : (
      t("COMMON_UNDEFINED")
    );

    return (
      <Form.Item name="FilterCreatorUserId" label={t("MODEL_CREATOR")}>
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item>
              <div
                style={{
                  backgroundColor: "#2e2e2e",
                  borderRadius: "2px",
                  padding: "6px 12px",
                }}
              >
                {filterCreatorUserView}
              </div>
            </Form.Item>
            <UserBrowserModal
              profiles={["CREATOR"]}
              visible={userBrowserVisible}
              onCancel={() => setUserBrowserVisible(false)}
              onSelect={onUserSelect}
            />
          </Col>
          <Col style={{ textAlign: "right" }}>
            {userCreator.Id && (
              <Button
                icon={<Icon type="delete" />}
                onClick={onUserClear}
                style={{ marginRight: "8px" }}
              />
            )}
            <Button
              icon={<Icon type="edit" />}
              onClick={() => setUserBrowserVisible(true)}
            />
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderMediaTypesField = () => {
    const initialValues = assetTypes
      .filter((el) =>
        assetCollection?.FilterTypes?.split(";").includes(el.Code)
      )
      .map((el) => el.Code);

    return (
      <Form.Item
        name="MediaType"
        label={t("MODEL_MEDIA_TYPE")}
        key="Media type"
        initialValue={initialValues || []}
      >
        <Choose mode={"multiple"}>
          {assetTypes &&
            assetTypes.map((el) => (
              <ChooseOption key={el.Code} value={el.Code}>
                {el.DisplayName}
              </ChooseOption>
            ))}
        </Choose>
      </Form.Item>
    );
  };

  const renderCategoriesField = () => {
    return (
      <Form.Item
        name="Categories"
        label={t("ASSET_CATEGORIES_LABEL")}
        key="Categories"
        initialValue={assetCollection?.Categories || []}
      >
        <LazyChoose<IAssetCategoryModel, AssetCategoryModel>
          loader={(search: string) =>
            assetCategoryService.search({
              PageNumber: 1,
              PageSize: 100,
              FullTextSearch: search,
            })
          }
          candidateToOption={(item) => ({
            label: item.Name,
            value: `${item.Id}`,
            item: {
              AssetCategoryId: item.Id,
              AssetCategoryName: item.Name,
              AssetCategoryCode: item.Code,
              RowVersion: item.RowVersion,
            },
          })}
          selectedToOption={(item) => ({
            label: item.AssetCategoryName!,
            value: `${item.AssetCategoryId!}`,
            item,
          })}
        />
      </Form.Item>
    );
  };

  const renderTimeFilterFields = () => {
    let timeTypeValue = "";

    if (
      !assetCollection?.FilterRecentlyFinished &&
      !assetCollection?.FilterFutureStart &&
      !assetCollection?.FilterOngoingNow
    ) {
      timeTypeValue = "ANY";
    } else if (assetCollection?.FilterRecentlyFinished) {
      timeTypeValue = "PAST";
    } else if (assetCollection?.FilterFutureStart) {
      timeTypeValue = "UPCOMING";
    } else if (assetCollection?.FilterOngoingNow) {
      timeTypeValue = "ONGOING";
    }

    return (
      <Form.Item
        name="FilterTime"
        label={t("MODEL_TIME")}
        key="FilterTime"
        initialValue={timeTypeValue}
      >
        <RadioGroup
          buttons
          data={formValues.assetTimeOptions.map(({ name, key }) => ({
            name: t(name),
            label: t(name),
            value: key,
          }))}
        />
      </Form.Item>
    );
  };

  const renderAssetVisibilityFilterFields = () => {
    let visibilityTypeValue = "";

    if (assetCollection?.FilterIsPremium !== undefined) {
      visibilityTypeValue = assetCollection.FilterIsPremium
        ? "PREMIUM"
        : "FREE";
    }

    return (
      <Form.Item
        name="FilterIsPremium"
        label={t("MODEL_ACCESSIBILITY")}
        key="FilterIsPremium"
        initialValue={visibilityTypeValue}
      >
        <RadioGroup
          buttons
          data={formValues.assetPaymentOptions.map(({ name, key }) => ({
            name: t(name),
            label: t(name),
            value: key,
          }))}
        />
      </Form.Item>
    );
  };

  const renderSortFields = () => {
    let sortBy = "";
    let sortDirection = "";
    const sortValue = assetCollection?.SortByExpression ?? "";
    const sortValues = sortValue.split(" ");

    if (sortValues.length > 0) {
      sortBy = sortValues[0].trim();
    }

    if (sortValues.length > 1) {
      sortDirection = sortValues[1].trim();
    }

    return (
      <Form.Item label={t("FILTERS_FORM_SORT_BY")}>
        <Row gutter={8}>
          <Col flex="auto">
            <Form.Item name="SortBy" key="SortBy" initialValue={sortBy}>
              <Choose
                placeholder={t("FILTERS_FORM_SELECT_SORT_VALUE")}
                allowClear
              >
                {formValues.assetSortData.map((data, index) => (
                  <ChooseOption key={index} value={data.key}>
                    {t(data.name)}
                  </ChooseOption>
                ))}
              </Choose>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="SortDirection"
              key="SortDirection"
              initialValue={sortDirection}
              labelCol={{ flex: "auto" }}
              wrapperCol={{ flex: "auto" }}
            >
              <SortDirectionInput />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const renderYearFilterField = () => {
    return (
      <Form.Item
        label={t("MODEL_YEAR_FROM")}
        style={{
          marginBottom: 0,
        }}
      >
        <Form.Item
          name="FilterYearFrom"
          key="FilterYearFrom"
          dependencies={["FilterYearTo"]}
          initialValue={
            assetCollection?.FilterYearFrom
              ? new Date(assetCollection?.FilterYearFrom, 0, 1)
              : undefined
          }
          rules={[
            ...isBefore(
              form,
              "FilterYearFrom",
              "FilterYearTo",
              "MODEL_YEAR_FROM_AFTER_YEAR_TO_VALIDATION_MESSAGE"
            ),
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 12px)",
          }}
        >
          <DatePicker
            format="YYYY"
            placeholder={t("MODEL_YEAR_FROM_PLACEHOLDER")}
            style={{ width: "100%" }}
            picker="year"
          />
        </Form.Item>
        <span
          style={{
            display: "inline-block",
            width: "24px",
            lineHeight: "32px",
            textAlign: "center",
          }}
        >
          {t("COMMON_TO")}
        </span>
        <Form.Item
          name="FilterYearTo"
          key="FilterYearTo"
          dependencies={["FilterYearFrom"]}
          initialValue={
            assetCollection?.FilterYearTo
              ? new Date(assetCollection?.FilterYearTo, 0, 1)
              : undefined
          }
          rules={[
            ...isAfter(
              form,
              "FilterYearFrom",
              "FilterYearTo",
              "MODEL_YEAR_TO_BEFORE_YEAR_FROM_VALIDATION_MESSAGE"
            ),
          ]}
          style={{
            display: "inline-block",
            width: "calc(50% - 12px)",
          }}
        >
          <DatePicker
            placeholder={t("MODEL_YEAR_TO_PLACEHOLDER")}
            style={{ width: "100%" }}
            picker="year"
          />
        </Form.Item>
      </Form.Item>
    );
  };

  const renderMyFavouritesFilterField = (radioIndex: number) => {
    return (
      <Form.Item
        name="FilterMyFavourites"
        initialValue={assetCollection?.FilterMyFavourites ?? false}
        valuePropName="checked"
        label={t("MODEL_ASSET_COLLECTION_FILTER_MY_FAVOURITES")}
      >
        <Switch onClick={(value) => changeRadioValues(radioIndex, value)} />
      </Form.Item>
    );
  };

  const renderRecentlyWatchedFilterField = (radioIndex: number) => {
    return (
      <Form.Item
        name="FilterRecentlyWatched"
        initialValue={assetCollection?.FilterRecentlyWatched ?? false}
        valuePropName="checked"
        label={t("MODEL_ASSET_COLLECTION_FILTER_RECENTLY_WATCHED")}
      >
        <Switch onClick={(value) => changeRadioValues(radioIndex, value)} />
      </Form.Item>
    );
  };

  const renderPurchasedFilterField = (radioIndex: number) => {
    return (
      <Form.Item
        name="FilterPurchased"
        initialValue={assetCollection?.FilterPurchased ?? false}
        valuePropName="checked"
        label={t("MODEL_ASSET_COLLECTION_FILTER_PURCHASED")}
      >
        <Switch onClick={(value) => changeRadioValues(radioIndex, value)} />
      </Form.Item>
    );
  };

  if (!assetCollection) {
    return <Spin />;
  }

  return (
    <Form
      id="AssetCollectionFiltersForm"
      name="AssetCollectionFiltersForm"
      {...formLayouts.formItemLayout}
      onFieldsChange={sendable.onFieldsChange}
      onFinish={onFinish}
      form={form}
    >
      <Row direction="column" justify="space-between" className="full-height">
        <Col>
          {renderCreatorField()}
          {renderMediaTypesField()}
          {renderCategoriesField()}
          {renderTimeFilterFields()}
          {renderAssetVisibilityFilterFields()}
          {renderYearFilterField()}
          {renderSortFields()}
          {renderMyFavouritesFilterField(0)}
          {renderRecentlyWatchedFilterField(1)}
          {renderPurchasedFilterField(2)}
          <Form.Item style={{ float: "right" }}>
            <Button
              disabled={!sendable.sendable}
              type="primary"
              htmlType="submit"
            >
              {t("BUTTON_SAVE")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
