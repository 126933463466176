export const formValues = {
  assetSortData: [
    {
      name: "ASSET_COLLECTION_FILTERS_ACCESS_TYPE",
      key: "a.[AssetAccessTypeCode]",
    },
    {
      name: "MODEL_TYPE_CODE",
      key: "a.[AssetTypeCode]",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_AGE_MIN_VALUE",
      key: "a.[AssetAgeRestrictionValueMin]",
    },
    {
      name: "MODEL_TITLE",
      key: "a.[Title]",
    },
    {
      name: "MODEL_DESCRIPTION",
      key: "a.[Description]",
    },
    {
      name: "MODEL_YEAR",
      key: "a.[Year]",
    },
    {
      name: "MODEL_DURATION_MILISECONDS",
      key: "a.[DurationMiliseconds]",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_PARENT_ASSET_ID",
      key: "a.[ParentAssetId]",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_ORDER_IN_PARENT",
      key: "a.[OrderInParent]",
    },
    {
      name: "MODEL_AVAILABLE_FROM",
      key: "a.[AvailableFrom]",
    },
    {
      name: "MODEL_AVAILABLE_TO",
      key: "a.[AvailableTo]",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_START_DATE_TIME",
      key: "a.[StartDateTime]",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_END_DATE_TIME",
      key: "a.[EndDateTime]",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_CREATOR_USER_ID",
      key: "a.[CreatorUserId]",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_IS_PREMIUM",
      key: "a.[IsPremium]",
    },
    {
      name: "MODEL_PRICE",
      key: "a.[Price]",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_CURRENCY_ID",
      key: "a.[CurrencyId]",
    },
    {
      name: "MODEL_CREATED",
      key: "a.[Created]",
    },
  ],
  sortOptions: [
    {
      name: "Ascending",
      key: "ASC",
    },
    {
      name: "Descending",
      key: "DESC",
    },
  ],
  assetPaymentOptions: [
    {
      name: "ASSET_COLLECTION_FILTERS_ANY",
      key: "",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_FREE",
      key: "FREE",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_PREMIUM",
      key: "PREMIUM",
    },
  ],
  assetTimeOptions: [
    {
      name: "ASSET_COLLECTION_FILTERS_ANY",
      key: "ANY",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_PAST",
      key: "PAST",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_UPCOMING",
      key: "UPCOMING",
    },
    {
      name: "ASSET_COLLECTION_FILTERS_ONGOING",
      key: "ONGOING",
    },
  ],
};

export const formLayouts = {
  formItemLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      xl: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      xl: { span: 18 },
    },
  },
  tailFormItemLayout: {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
      xl: {
        span: 18,
        offset: 6,
      },
    },
  },
};
