export enum AssetContentType {
  /**
   * video types
   */
  MP4 = "video/mp4",
  MP2T = "video/mp2t",
  HLS = "application/x-mpegURL",
  MPD = "application/dash+xml",
  HSS = "application/vnd.ms-sstr+xml",
  /**
   * audio types
   */
  MP3 = "audio/mpeg",
  OGG = "audio/ogg",
  M4A = "audio/m4a",
  AAC = "audio/aac",
  /**
   * text types
   */
  HTML = "text/html",
}
