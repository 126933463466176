import { useCallback, useEffect, useRef, useState } from "react";
import {
  IAssetContentModel,
  IAssetContentPlayInfoModel,
} from "@bms/common-services";
import { getPlayerDRMConfig } from "../components/Player/utils";

// we need to import the shaka ui module instead of the regular module to gain
// access to the Overlay constructor and also ts-ignore is required for correct
// import in type script
// @ts-ignore
import shaka from "shaka-player/dist/shaka-player.ui";
import "shaka-player/dist/controls.css";

export const isSafariBrowser =
  navigator.userAgent.indexOf("Safari") > -1 &&
  navigator.userAgent.indexOf("Chrome") <= -1;

type IPlayerInitOptions = {
  assetContent?: IAssetContentModel;
  playInfo?: IAssetContentPlayInfoModel;
  allowCrossSiteCredentials?: boolean;
};

export const useShakaPlayer = () => {
  const [player, setPlayer] = useState<any>();
  const [videoContainerEl, setVideoContainerEl] = useState<HTMLDivElement>();
  const [videoEl, setVideoEl] = useState<HTMLVideoElement>();
  const [isInitialized, setIsInitialized] = useState(false);
  const [allowCrossSiteCredentials, setAllowCrossSiteCredentials] = useState(
    false
  );

  const assetContentRef = useRef<IAssetContentModel | undefined>(undefined);
  const playInfoRef = useRef<IAssetContentPlayInfoModel | undefined>(undefined);

  const isDRMProtected = Boolean(assetContentRef.current?.DrmProvider);

  useEffect(() => {
    if (videoEl && videoContainerEl) {
      // noinspection JSIgnoredPromiseFromCall
      initShakaPlayer();
    }
  }, [videoEl, videoContainerEl]);

  const initPlayer = (
    videoRef: HTMLVideoElement,
    videoContainerRef: HTMLDivElement,
    options?: IPlayerInitOptions
  ) => {
    assetContentRef.current = options?.assetContent;
    playInfoRef.current = options?.playInfo;

    setAllowCrossSiteCredentials(options?.allowCrossSiteCredentials || false);
    setVideoContainerEl(videoContainerRef);
    setVideoEl(videoRef);
  };

  const initShakaPlayer = async () => {
    shaka.polyfill.installAll();

    if (videoEl && shaka.Player.isBrowserSupported()) {
      await loadShakaPlayer();
    } else {
      console.error("Browser not supported!");
    }
  };

  const customRequestFilter = useCallback(
    (type: unknown, request: any) => {
      if (allowCrossSiteCredentials && !isDRMProtected) {
        request.allowCrossSiteCredentials = true;
      }
      // This function filters manifest request and add custom headers:
      if (
        type == shaka.net.NetworkingEngine.RequestType.MANIFEST ||
        type == shaka.net.NetworkingEngine.RequestType.SEGMENT
      ) {
        const headers =
          playInfoRef.current?.Headers || assetContentRef.current?.Headers;

        if (headers) {
          for (const header of headers) {
            if (header.Value) {
              request.headers[header.Name] = header.Value;
            }
          }
        }
      }
    },
    [
      allowCrossSiteCredentials,
      isDRMProtected,
      playInfoRef.current,
      assetContentRef.current,
    ]
  );

  const loadShakaPlayer = useCallback(async () => {
    const shakaPlayer = new shaka.Player(videoEl);

    new shaka.ui.Overlay(shakaPlayer, videoContainerEl, videoEl);

    (window as any).player = shakaPlayer;

    console.info("Shaka player version:", shaka.Player.version);

    shakaPlayer.addEventListener("error", handleErrorEvent);

    shakaPlayer
      .getNetworkingEngine()
      .registerRequestFilter(customRequestFilter);

    if (isDRMProtected && playInfoRef.current) {
      shakaPlayer.configure(await getPlayerDRMConfig(playInfoRef.current));

      if (isSafariBrowser) {
        shaka.polyfill.PatchedMediaKeysApple.install();

        shakaPlayer
          .getNetworkingEngine()
          .registerRequestFilter(shaka.util.FairPlayUtils.ezdrmFairPlayRequest);

        shakaPlayer
          .getNetworkingEngine()
          .registerResponseFilter(
            shaka.util.FairPlayUtils.commonFairPlayResponse
          );

        shakaPlayer.configure(
          "drm.initDataTransform",
          shaka.util.FairPlayUtils.ezdrmInitDataTransform
        );
      }
    }

    setIsInitialized(true);
    setPlayer(shakaPlayer);
  }, [videoContainerEl, videoEl]);

  const loadContent = async (contentUrl: string, contentType?: string) => {
    try {
      await player.load(contentUrl, null, contentType);
      console.log("The video has now been loaded!");
    } catch (e) {
      onError(e);
    }
  };

  const clearPlayer = () => {
    if (player) {
      player.destroy();
    }
  };

  const handleErrorEvent = (event: any) => {
    onError(event.detail);
  };

  const onError = (e: any) => {
    console.error("Error code", e.code, "object", e);
  };

  return { initPlayer, loadContent, clearPlayer, isInitialized };
};
