import {
  ComponentType,
  ComponentTypeHelper,
  GuidHelper,
  IApplicationComponentModel,
  IApplicationScreenModel,
  PlatformType,
  RecordStatus,
  ScreenType,
  ScreenTypeHelper,
} from "@bms/common-services";
import { Empty } from "@bms/common-ui";
import React, { useContext } from "react";
import {
  ApplicationScreenContext,
  IApplicationScreenContext,
} from "../../context";
import { DesignerModule } from "../../modules";

import "./ApplicationScreenProperties.scss";
import { useTranslation } from "react-i18next";

export interface IApplicationComponentPropertiesProps {}

export const ApplicationScreenProperties: React.FC<IApplicationComponentPropertiesProps> = () => {
  const { screen, onComponentChange, onComponentAdd } = useContext<
    IApplicationScreenContext
  >(ApplicationScreenContext);
  const { t } = useTranslation();

  const components = screen?.Components ?? [];
  let component = components.find(
    (component) =>
      component.ComponentTypeCode === ComponentType.ScreenProperties
  );

  if (!component) {
    component = {
      Id: -1,
      Guid: GuidHelper.newGuid(),
      ApplicationConfigurationId: screen?.ApplicationConfigurationId,
      Name: `${ScreenTypeHelper.getDescription(
        ScreenTypeHelper.getValue(screen?.ScreenTypeCode)
      )} - ${ComponentTypeHelper.getDescription(
        ComponentType.ScreenProperties
      )}`,
      ComponentTypeCode: ComponentType.ScreenProperties,
      PlatformCode: PlatformType.Any,
      Sequence: 0,
      RecordStatus: RecordStatus.NoChange,
    };
  }

  const onComponentChanged = (component: IApplicationComponentModel) => {
    if (!component.ApplicationScreenId) {
      onComponentAdd?.(component);
    } else {
      onComponentChange?.(component);
    }
  };

  const renderScreenProperties = (
    component: IApplicationComponentModel,
    screen?: IApplicationScreenModel
  ) => {
    switch (screen?.ScreenTypeCode) {
      case ScreenType.Intro:
        return (
          <DesignerModule.Components.ApplicationIntroProperties
            key={`ApplicationIntroProperties-${component.Id}`}
            component={component}
            onComponentChange={onComponentChanged}
          />
        );
      case ScreenType.Register:
        return (
          <DesignerModule.Components.ApplicationRegisterProperties
            key={`ApplicationRegisterProperties-${component.Id}`}
            component={component}
            onComponentChange={onComponentChanged}
          />
        );
      case ScreenType.RegisterSuccess:
        return (
          <DesignerModule.Components.ApplicationRegisterSuccessProperties
            key={`ApplicationRegisterSuccessProperties-${component.Id}`}
            component={component}
            onComponentChange={onComponentChanged}
          />
        );
      case ScreenType.ApplicationFooter:
        return (
          <DesignerModule.Components.ApplicationFooterProperties
            key={`ApplicationFooterProperties-${component.Id}`}
            component={component}
            onComponentChange={onComponentChanged}
          />
        );
      case ScreenType.Search:
        return (
          <DesignerModule.Components.ApplicationSearchProperties
            key={`ApplicationSearchProperties-${component.Id}`}
            component={component}
            onComponentChange={onComponentChanged}
          />
        );
      case ScreenType.Custom: {
        return (
          <DesignerModule.Components.ApplicationCustomProperties
            key={`ApplicationCustomProperties-${component.Id}`}
            component={component}
            onComponentChange={onComponentChanged}
          />
        );
      }

      default:
        return <Empty>{t("SCREEN_PROPERTIES__NOT_KNOWN_COMPONENT")}</Empty>;
    }
  };

  if (!component || component.RecordStatus === RecordStatus.Deleted) {
    return <Empty>{t("SCREEN_PROPERTIES__SELECT_COMPONENT")}</Empty>;
  }

  return <>{renderScreenProperties(component, screen)}</>;
};
