import { ICommonAppState, IUserConsentModel } from "@bms/common-services";
import {
  Checkbox,
  ITableColumnProps,
  PageContent,
  Table,
} from "@bms/common-ui";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const UserConsents = () => {
  const { t } = useTranslation();
  const { consents } = useSelector((state: ICommonAppState) => state.user);

  const getColumnsProps = (): Array<ITableColumnProps<IUserConsentModel>> => {
    return [
      {
        key: "ConsentCode",
        dataIndex: "ConsentCode",
        title: t("USER_CONSENTS_CODE"),
      },
      {
        key: "ConsentName",
        dataIndex: "ConsentName",
        title: t("USER_CONSENTS_NAME"),
      },
      {
        key: "Accepted",
        dataIndex: "ConsentAccepted",
        title: t("USER_CONSENTS_ACCEPTED"),
        width: 120,
        render: (text, record) => (
          <Checkbox checked={record.Accepted} disabled={true} />
        ),
      },
      {
        key: "ConsentRequired",
        dataIndex: "ConsentRequired",
        title: t("MODEL_REQUIRED"),
        width: 120,
        render: (text, record) => (
          <Checkbox checked={record.ConsentRequired} disabled={true} />
        ),
      },
      {
        key: "ConsentUpToDate",
        dataIndex: "ConsentUpToDate",
        title: t("MODEL_UP_TO_DATE"),
        width: 120,
        render: (text, record) => (
          <Checkbox checked={record.ConsentUpToDate} disabled={true} />
        ),
      },
    ];
  };

  if (!consents?.length) {
    return null;
  }

  return (
    <PageContent className="UserConsents">
      <Table<IUserConsentModel>
        columns={getColumnsProps()}
        rowKey="ConsentId"
        dataSource={consents}
        pagination={false}
      />
    </PageContent>
  );
};
