import { push } from "connected-react-router";
import { omit } from "lodash";
import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import { empty, from, of } from "rxjs";
import { catchError, flatMap, map, mapTo, switchMap } from "rxjs/operators";
import { OperationResultType } from "../../enums";
import {
  AgeRestrictionModel,
  IErrorModel,
  IProfilesListModel,
  IUserBrandingSettingsModel,
  IUserConsentModel,
  IUserModel,
  IUsersListModel,
  OperationResult,
  UploadFileInfoModel,
} from "../../models";
import {
  ProfileService,
  StorageManager,
  StorageService,
  UserConsentsService,
  UserService,
} from "../../services";
import { ICommonAppState } from "../types";
import {
  addFamilyMemberFailure,
  addFamilyMemberSuccess,
  anonymizeUserFailure,
  anonymizeUserSuccess,
  browseUsersFailure,
  browseUsersSuccess,
  changeUserPasswordFailure,
  changeUserPasswordSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  getAgeRestrictionsFailure,
  getAgeRestrictionsSuccess,
  getFamilyMembersFailure,
  getFamilyMembersSuccess,
  getProfileDetails,
  getProfileDetailsFailure,
  getProfileDetailsSuccess,
  getProfileFailure,
  getProfileSuccess,
  getPublicProfileFailure,
  getPublicProfileSuccess,
  getUserBrandingSettingsFailure,
  getUserBrandingSettingsSuccess,
  getUserConsentsFailure,
  getUserConsentsSuccess,
  getUserDetailsFailure,
  getUserDetailsSuccess,
  getUserFailure,
  getUserSuccess,
  insertUserFailure,
  insertUserSuccess,
  resendConfirmationEmailsFailure,
  resendConfirmationEmailsSuccess,
  resetUserPasswordFailure,
  resetUserPasswordSuccess,
  searchUsersFailure,
  searchUsersSuccess,
  selectProfilesFailure,
  selectProfilesSuccess,
  updateProfileFailure,
  updateProfileSuccess,
  updateUserBrandingFailure,
  updateUserFailure,
  updateUserSuccess,
} from "./actions";
import * as Consts from "./consts";
import {
  IAddFamilyMemberAction,
  IAddFamilyMemberSuccessAction,
  IAnonymizeUserAction,
  IBrowseUsersAction,
  IChangeUserPasswordAction,
  IDeleteUserAction,
  IGetAgeRestrictionsAction,
  IGetFamilyMembersAction,
  IGetProfileAction,
  IGetProfileDetailsAction,
  IGetProfileSuccessAction,
  IGetPublicProfileAction,
  IGetUserAction,
  IGetUserBrandingSettingsAction,
  IGetUserConsentsAction,
  IGetUserDetailsAction,
  IInsertUserAction,
  IResendConfirmationEmailsAction,
  IResetUserPasswordAction,
  ISearchUserAction,
  ISelectProfilesAction,
  IUpdateProfileAction,
  IUpdateProfileSuccessAction,
  IUpdateUserAction,
} from "./types";

const userService: UserService = new UserService();
const userConsentsService: UserConsentsService = new UserConsentsService();
const profileService: ProfileService = new ProfileService();
const storageService: StorageService = StorageService.getInstance();

const getProfileEpic = (
  action$: ActionsObservable<IGetProfileAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_PROFILE),
    switchMap(() =>
      userService.getProfile().pipe(
        map((profile: IUserModel) => getProfileSuccess(profile)),
        catchError((error: IErrorModel) => of(getProfileFailure(error)))
      )
    )
  );

const getProfileDetailsEpic = (
  action$: ActionsObservable<IGetProfileDetailsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_PROFILE_DETAILS),
    switchMap((action: IGetProfileDetailsAction) =>
      userService.getProfileDetails(action.payload).pipe(
        map((profile: IUserModel) => getProfileDetailsSuccess(profile)),
        catchError((error: IErrorModel) => of(getProfileDetailsFailure(error)))
      )
    )
  );

const getPublicProfileEpic = (
  action$: ActionsObservable<IGetPublicProfileAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_PUBLIC_PROFILE),
    switchMap((action: IGetPublicProfileAction) =>
      userService.getPublicProfile(action.userId).pipe(
        map((profile: IUserModel) =>
          getPublicProfileSuccess(action.userId, profile)
        ),
        catchError((error: IErrorModel) =>
          of(getPublicProfileFailure(action.userId, error))
        )
      )
    )
  );

const updateProfileEpic = (
  action$: ActionsObservable<IUpdateProfileAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_PROFILE),
    switchMap((action: IUpdateProfileAction) => {
      const filesToUpload: Array<Promise<
        OperationResult<UploadFileInfoModel>
      >> = [];

      if (action.payload.AvatarUploadInfo) {
        if (action.payload.AvatarFile) {
          action.payload.AvatarUploadInfo.Key = "AvatarFile";
          filesToUpload.push(
            storageService.uploadFile(
              action.payload.AvatarFile,
              action.payload.AvatarUploadInfo
            )
          );
        } else {
          action.payload.AvatarUploadInfo = undefined;
        }
      }

      if (
        action.payload.Branding &&
        action.payload.Branding.WallpaperUploadInfo
      ) {
        if (action.payload.Branding.WallpaperFile) {
          action.payload.Branding.WallpaperUploadInfo.Key = "WallpaperFile";
          filesToUpload.push(
            storageService.uploadFile(
              action.payload.Branding.WallpaperFile,
              action.payload.Branding.WallpaperUploadInfo
            )
          );
        } else {
          action.payload.Branding.WallpaperUploadInfo = undefined;
        }
      }

      return Promise.all(filesToUpload)
        .then(
          (uploadFilesInfo: Array<OperationResult<UploadFileInfoModel>>) => {
            if (uploadFilesInfo && uploadFilesInfo.length > 0) {
              for (const uploadFileInfo of uploadFilesInfo) {
                if (
                  uploadFileInfo.ResultType === OperationResultType.Ok &&
                  uploadFileInfo.Result
                ) {
                  switch (uploadFileInfo.Result.Key) {
                    case "AvatarFile":
                      action.payload.AvatarPath = uploadFileInfo.Result.Path;
                      action.payload.AvatarFile = undefined;
                      action.payload.AvatarUploadInfo = undefined;

                      break;
                    case "WallpaperFile":
                      if (action.payload.Branding) {
                        action.payload.Branding.WallpaperPath =
                          uploadFileInfo.Result.Path;
                        action.payload.Branding.WallpaperFile = undefined;
                        action.payload.Branding.WallpaperUploadInfo = undefined;
                      }
                      break;
                  }
                }
              }
            }

            return;
          }
        )
        .then(() => {
          return userService
            .updateProfile({
              ...omit(state.value.user.profile, "Branding"), // FIXME: Sending Branding causes API errors and should not be sent for now
              ...action.payload,
            })
            .pipe(
              map((profile: IUserModel) => {
                return updateProfileSuccess(profile, action.redirectUrl);
              }),
              catchError((error: IErrorModel) =>
                of(updateProfileFailure(error))
              )
            )
            .toPromise();
        })
        .catch((error: IErrorModel) => {
          return updateUserBrandingFailure(error);
        });
    })
  );

const updateProfileSuccessEpic = (
  action$: ActionsObservable<IUpdateProfileSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_PROFILE_SUCCESS),
    map((action: IUpdateProfileSuccessAction) => {
      if (action.payload.redirectUrl) {
        return push(action.payload.redirectUrl);
      } else {
        return getProfileDetails({ IncludeUploadFilesInfo: true });
      }
    })
  );

const saveProfileInStorageEpic = (
  action$: ActionsObservable<
    IGetProfileSuccessAction | IUpdateProfileSuccessAction
  >
) =>
  action$.pipe(
    ofType(Consts.GET_PROFILE_SUCCESS, Consts.UPDATE_PROFILE_SUCCESS),
    switchMap(
      (action: IGetProfileSuccessAction | IUpdateProfileSuccessAction) => {
        StorageManager.setValue("user", action.payload.profile);
        return empty();
      }
    )
  );

const getUserBrandingSettingsEpic = (
  action$: ActionsObservable<IGetUserBrandingSettingsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_USER_BRANDING_SETTINGS),
    switchMap((action: IGetUserBrandingSettingsAction) =>
      userService.getBrandingSettings().pipe(
        map((response: IUserBrandingSettingsModel) => {
          return getUserBrandingSettingsSuccess(response);
        }),
        catchError((error: IErrorModel) =>
          of(getUserBrandingSettingsFailure(error))
        )
      )
    )
  );

const addFamilyMemberEpic = (
  action$: ActionsObservable<IAddFamilyMemberAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.ADD_FAMILY_MEMBER),
    switchMap((action: IAddFamilyMemberAction) =>
      userService.addFamilyMember(action.data).pipe(
        map((response: IUserModel) => addFamilyMemberSuccess(response)),
        catchError((error: IErrorModel) => of(addFamilyMemberFailure(error)))
      )
    )
  );

const addFamilyMemberSuccessEpic = (
  action$: ActionsObservable<IAddFamilyMemberSuccessAction>
) =>
  action$.pipe(
    ofType(Consts.ADD_FAMILY_MEMBER_SUCCESS),
    mapTo(push("/family"))
  );

const getFamilyMembersEpic = (
  action$: ActionsObservable<IGetFamilyMembersAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_FAMILY_MEMBERS),
    switchMap(() =>
      userService.getFamilyMembers().pipe(
        map((response: IUserModel[]) => getFamilyMembersSuccess(response)),
        catchError((error: IErrorModel) => of(getFamilyMembersFailure(error)))
      )
    )
  );

const getAgeRestrictionsEpic = (
  action$: ActionsObservable<IGetAgeRestrictionsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_AGE_RESTRICTIONS),
    switchMap(() =>
      userService.getAgeRestrictions().pipe(
        map((data: AgeRestrictionModel[]) => getAgeRestrictionsSuccess(data)),
        catchError((error: IErrorModel) => of(getAgeRestrictionsFailure(error)))
      )
    )
  );

const searchUsersEpic = (
  action$: ActionsObservable<ISearchUserAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SEARCH_USER),
    switchMap((action: ISearchUserAction) => {
      return userService.search(action.filter).pipe(
        map((data: IUsersListModel) => {
          data.Filter = action.filter;

          return searchUsersSuccess(data);
        }),
        catchError((error: IErrorModel) => of(searchUsersFailure(error)))
      );
    })
  );

const getUserEpic = (
  action$: ActionsObservable<IGetUserAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_USER),
    switchMap((action: IGetUserAction) =>
      userService.get(action.id).pipe(
        map((data: IUserModel) => {
          return getUserSuccess(data);
        }),
        catchError((error: IErrorModel) => of(getUserFailure(error)))
      )
    )
  );

const getUserDetailsEpic = (
  action$: ActionsObservable<IGetUserDetailsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_USER_DETAILS),
    switchMap((action: IGetUserDetailsAction) =>
      userService.getDetails(action.id, action.payload).pipe(
        map((data: IUserModel) => getUserDetailsSuccess(data)),
        catchError((error: IErrorModel) => of(getUserDetailsFailure(error)))
      )
    )
  );

const getUserConsentsEpic = (
  action$: ActionsObservable<IGetUserConsentsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.GET_USER_CONSENTS),
    switchMap((action: IGetUserConsentsAction) =>
      userConsentsService.selectByUser(action.id).pipe(
        map((data: IUserConsentModel[]) => getUserConsentsSuccess(data)),
        catchError((error: IErrorModel) => of(getUserConsentsFailure(error)))
      )
    )
  );

const deleteUserEpic = (
  action$: ActionsObservable<IDeleteUserAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.DELETE_USER),
    switchMap((action: IDeleteUserAction) =>
      userService.delete(action.payload).pipe(
        map(() => {
          return deleteUserSuccess();
        }),
        catchError((error: IErrorModel) => of(deleteUserFailure(error)))
      )
    )
  );

const anonymizeUserEpic = (
  action$: ActionsObservable<IAnonymizeUserAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.ANONYMIZE_USER),
    switchMap((action: IAnonymizeUserAction) =>
      userService.anonymize(action.payload).pipe(
        map(() => {
          return anonymizeUserSuccess();
        }),
        catchError((error: IErrorModel) => of(anonymizeUserFailure(error)))
      )
    )
  );

const insertUserEpic = (
  action$: ActionsObservable<IInsertUserAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.INSERT_USER),
    switchMap((action: IInsertUserAction) =>
      userService.insert(action.payload).pipe(
        flatMap((data) =>
          data.Id
            ? userService.getDetails(data.Id, { IncludeUploadFilesInfo: true })
            : of(data)
        ),
        flatMap((data: IUserModel) =>
          data.AvatarUploadInfo && action.payload.AvatarFile
            ? from(
                storageService.uploadFile(action.payload.AvatarFile, {
                  ...data.AvatarUploadInfo,
                  Key: "AvatarFile",
                })
              ).pipe(
                flatMap((upload) =>
                  userService.update({
                    ...data,
                    AvatarUploadInfo: undefined,
                    AvatarFile: undefined,
                    AvatarPath: upload.Result?.Path,
                  })
                )
              )
            : of(data)
        ),
        map((data: IUserModel) => insertUserSuccess(data)),
        catchError((error: IErrorModel) => of(insertUserFailure(error)))
      )
    )
  );

const updateUserEpic = (
  action$: ActionsObservable<IUpdateUserAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.UPDATE_USER),
    switchMap((action: IUpdateUserAction) => {
      const filesToUpload: Array<Promise<
        OperationResult<UploadFileInfoModel>
      >> = [];

      if (action.payload.AvatarUploadInfo) {
        if (action.payload.AvatarFile) {
          action.payload.AvatarUploadInfo.Key = "AvatarFile";
          filesToUpload.push(
            storageService.uploadFile(
              action.payload.AvatarFile,
              action.payload.AvatarUploadInfo
            )
          );
        } else {
          action.payload.AvatarUploadInfo = undefined;
        }
      }

      if (
        action.payload.Branding &&
        action.payload.Branding.WallpaperUploadInfo
      ) {
        if (action.payload.Branding.WallpaperFile) {
          action.payload.Branding.WallpaperUploadInfo.Key = "WallpaperFile";
          filesToUpload.push(
            storageService.uploadFile(
              action.payload.Branding.WallpaperFile,
              action.payload.Branding.WallpaperUploadInfo
            )
          );
        } else {
          action.payload.Branding.WallpaperUploadInfo = undefined;
        }
      }

      return Promise.all(filesToUpload)
        .then(
          (uploadFilesInfo: Array<OperationResult<UploadFileInfoModel>>) => {
            if (uploadFilesInfo && uploadFilesInfo.length > 0) {
              for (const uploadFileInfo of uploadFilesInfo) {
                if (
                  uploadFileInfo.ResultType === OperationResultType.Ok &&
                  uploadFileInfo.Result
                ) {
                  switch (uploadFileInfo.Result.Key) {
                    case "AvatarFile":
                      action.payload.AvatarPath = uploadFileInfo.Result.Path;
                      action.payload.AvatarFile = undefined;
                      action.payload.AvatarUploadInfo = undefined;

                      break;
                    case "WallpaperFile":
                      if (action.payload.Branding) {
                        action.payload.Branding.WallpaperPath =
                          uploadFileInfo.Result.Path;
                        action.payload.Branding.WallpaperFile = undefined;
                        action.payload.Branding.WallpaperUploadInfo = undefined;
                      }
                      break;
                  }
                }
              }
            }

            return;
          }
        )
        .then(() => {
          return action.payload.Locked && action.payload.LockoutEnd
            ? userService
                .lockUser({
                  Id: action.payload.Id,
                  LockoutEnd: action.payload.LockoutEnd,
                })
                .toPromise()
            : userService.unlockUser({ Id: action.payload.Id }).toPromise();
        })
        .then(() => {
          return userService
            .update({ ...state.value.user.user, ...action.payload })
            .pipe(
              map((data: IUserModel) => {
                return updateUserSuccess(data);
              }),
              catchError((error: IErrorModel) => of(updateUserFailure(error)))
            )
            .toPromise();
        })
        .catch((error: IErrorModel) => {
          return updateUserBrandingFailure(error);
        });
    })
  );

const resetPasswordEpic = (
  action$: ActionsObservable<IResetUserPasswordAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.RESET_USER_PASSWORD),
    switchMap((action: IResetUserPasswordAction) =>
      userService.resetPassword(action.payload).pipe(
        map((data: boolean) => resetUserPasswordSuccess(data)),
        catchError((error: IErrorModel) => of(resetUserPasswordFailure(error)))
      )
    )
  );

const selectProfilesEpic = (
  action$: ActionsObservable<ISelectProfilesAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.SELECT_PROFILES),
    switchMap((action: ISelectProfilesAction) => {
      return profileService.select().pipe(
        map((data: IProfilesListModel) => {
          return selectProfilesSuccess(data);
        }),
        catchError((error: IErrorModel) => of(selectProfilesFailure(error)))
      );
    })
  );

const browseUsersEpic = (
  action$: ActionsObservable<IBrowseUsersAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.BROWSE_USERS),
    switchMap((action) =>
      userService.search(action.filter).pipe(
        map((data: IUsersListModel) => {
          data.Filter = action.filter;

          return browseUsersSuccess(data);
        }),
        catchError((error: IErrorModel) => of(browseUsersFailure(error)))
      )
    )
  );

const changeUserPasswordEpic = (
  action$: ActionsObservable<IChangeUserPasswordAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.CHANGE_USER_PASSWORD),
    switchMap((action: IChangeUserPasswordAction) =>
      userService.changePassword(action.payload).pipe(
        map(() => changeUserPasswordSuccess(true)),
        catchError((error: IErrorModel) => of(changeUserPasswordFailure(error)))
      )
    )
  );

const resendConfirmationEmailsEpic = (
  action$: ActionsObservable<IResendConfirmationEmailsAction>,
  state: StateObservable<ICommonAppState>
) =>
  action$.pipe(
    ofType(Consts.RESEND_CONFIRMATION_EMAILS),
    switchMap((action) =>
      userService.resendConfirmationEmails(action.payload).pipe(
        map(() => {
          return resendConfirmationEmailsSuccess();
        }),
        catchError((error: IErrorModel) =>
          of(resendConfirmationEmailsFailure(error))
        )
      )
    )
  );

export const userEpics = [
  getProfileEpic,
  getProfileDetailsEpic,
  getPublicProfileEpic,
  updateProfileEpic,
  updateProfileSuccessEpic,
  addFamilyMemberEpic,
  addFamilyMemberSuccessEpic,
  getFamilyMembersEpic,
  getAgeRestrictionsEpic,
  getUserBrandingSettingsEpic,
  searchUsersEpic,
  getUserEpic,
  getUserDetailsEpic,
  getUserConsentsEpic,
  deleteUserEpic,
  anonymizeUserEpic,
  resetPasswordEpic,
  insertUserEpic,
  updateUserEpic,
  saveProfileInStorageEpic,
  selectProfilesEpic,
  browseUsersEpic,
  changeUserPasswordEpic,
  resendConfirmationEmailsEpic,
];
