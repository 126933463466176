import {
  IUserModel,
  NotificationRecipientsService,
  RecordStatus,
  useDataLoader,
  UserService,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  Heading,
  InputSearch,
  ITableColumnProps,
  ITableFilter,
  ITablePaginationConfig,
  ITableRowSelection,
  Modal,
  Spin,
  Table,
  Tag,
  useAppFeedback,
} from "@bms/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTableDataProvider } from "../../../../../../helpers";

import "./UsersModal.scss";

interface IUserModalComponent {
  notificationId: number;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  refreshRecipients: () => void;
}

interface ISeletedUsersIdsObject {
  page: number | undefined;
  users: number[];
}

const userService = new UserService().promisify();
const recipientNotificationService = new NotificationRecipientsService().promisify();

function sequenceMap<T>(array: T[], mapper: (item: T) => Promise<any>) {
  return array.reduce(
    (previous, next) => previous.then(() => mapper(next)),
    Promise.resolve()
  );
}

export const UsersModal = ({
  notificationId,
  isVisible,
  setIsVisible,
  refreshRecipients,
}: IUserModalComponent) => {
  const { t } = useTranslation();
  const { notification } = useAppFeedback();

  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);
  const [seletedUsersIdsObject, setSeletedUsersIdsObject] = useState<
    ISeletedUsersIdsObject[]
  >([]);

  const recipientsInNotificationLoader = useDataLoader({
    loader: () =>
      recipientNotificationService.search({
        NotificationId: notificationId,
        IncludeCount: true,
      }),
    deps: [notificationId],
  });

  const {
    dataLoader: userDataLoader,
    filters,
    pagination,
  } = useTableDataProvider({
    filtersSchema: {
      FullTextSearch: "string",
      Locked: "boolean",
      EmailConfirmed: "boolean",
    },
    loader: (filters, pagination) => {
      return userService.search({
        ...filters,
        ...pagination,
      });
    },
    deps: [],
  });

  const alreadyRecipientsId = recipientsInNotificationLoader.data?.Entities.map(
    (recipient) => recipient.UserId
  );

  const onSearch = (value: string) => {
    filters.update((oldFilters) => ({
      ...oldFilters,
      FullTextSearch: value || undefined,
    }));
  };

  const getUserColumns = (): Array<ITableColumnProps<IUserModel>> => [
    {
      key: "FullUserName",
      dataIndex: "FullName",
      title: t("MODEL_NAME"),
    },
    {
      key: "Email",
      dataIndex: "Email",
      title: t("TAB_RECIPIENTS_RECIPIENT_EMAIL"),
    }, {
      key: "Locked",
      dataIndex: "Locked",
      align: "center",
      width: "150px",
      title: t("USERS_LIST_TABLE_STATUS_COLUMN", "Status"),
      filters: [
        {
          text: t("USERS_LIST_TABLE_STATUS_COLUMN_LOCKED_FILTER", "Locked"),
          value: "true",
        },
        {
          text: t("USERS_LIST_TABLE_STATUS_COLUMN_ACTIVE_FILTER", "Active"),
          value: "false",
        },
      ],
      filteredValue: filters.asTableArray.Locked,
      render: (_: any, row: IUserModel) => (
        <Tag color={row.Locked ? "#c44" : "#418841"}>
          {row.Locked
            ? t("USERS_LIST_TABLE_STATUS_COLUMN_LOCKED_FILTER", "Locked")
            : t("USERS_LIST_TABLE_STATUS_COLUMN_ACTIVE_FILTER", "Active")}
        </Tag>
      ),
    },
    {
      key: "EmailConfirmed",
      dataIndex: "EmailConfirmed",
      align: "center",
      width: "170px",
      title: t("USERS_LIST_TABLE_EMAIL_CONFIRMED_COLUMN", "Email status"),
      filters: [
        {
          text: t("USERS_LIST_TABLE_CONFIRMED_FILTER", "Confirmed"),
          value: "true",
        },
        {
          text: t("USERS_LIST_TABLE_UNCONFIRMED_FILTER", "Unconfirmed"),
          value: "false",
        },
      ],
      filteredValue: filters.asTableArray.EmailConfirmed,
      render: (_: any, row: IUserModel) => (
        <Tag color={row.EmailConfirmed ? "#418841" : "#787878"}>
          {row.EmailConfirmed
            ? t("USERS_LIST_TABLE_CONFIRMED_FILTER", "Confirmed")
            : t("USERS_LIST_TABLE_UNCONFIRMED_FILTER", "Unconfirmed")}
        </Tag>
      ),
    },
  ];

  const userTablePagination: ITablePaginationConfig = {
    pageSize: pagination.current.PageSize,
    total: userDataLoader.data?.TotalCount,
    current: pagination.current.PageNumber,
    showTotal: (total, range) =>
      t("TABLE_PAGINATION_TOTAL", {
        rangeFrom: range[0],
        rangeTo: range[1],
        total: total,
      }),
    showSizeChanger: true,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "30", "50", "100", "500"],
  };

  const onTableChange = (
    tablePagination: ITablePaginationConfig,
    tableFilters: ITableFilter
  ) => {
    filters.update((oldFilters) => ({
      ...oldFilters,
      ...tableFilters,
    }));
    pagination.props.onChange(tablePagination.current, tablePagination.pageSize);
  };

  const checkElements = (checked: React.Key[]) => {
    const currentPage = pagination.current.PageNumber;
    const convertedChecked = checked.map((el) => parseInt(el.toString()));
    const temporaryObject = { page: currentPage, users: convertedChecked };
    let temporarySelectedUsersIdsObjct = seletedUsersIdsObject;

    let tempResult = [];
    if (seletedUsersIdsObject.some((element) => element.page === currentPage)) {
      let selectedUsersFromOtherPages = seletedUsersIdsObject.filter(
        (element) => element.page !== currentPage
      );
      selectedUsersFromOtherPages.push(temporaryObject);
      tempResult = selectedUsersFromOtherPages;
      setSeletedUsersIdsObject(selectedUsersFromOtherPages);
    } else {
      temporarySelectedUsersIdsObjct.push(temporaryObject);
      tempResult = temporarySelectedUsersIdsObjct;
      setSeletedUsersIdsObject(temporarySelectedUsersIdsObjct);
    }

    const result = tempResult.map((el) => el.users).flat();
    setSelectedUsersIds(result);
  };

  const rowSelection: ITableRowSelection<IUserModel> = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IUserModel[]) => {
      checkElements(selectedRowKeys);
    },
    type: "checkbox",
    selectedRowKeys: selectedUsersIds,
    getCheckboxProps: (record: IUserModel) => ({
      disabled: alreadyRecipientsId?.includes(record.Id),
    }),
  };

  const [insertRecipientInNotification] = useServiceCaller(
    async (recipientIds: number[]) => {
      let errorOccurred = false;
      let errorMessage;
      setIsProcessing(true);
      await sequenceMap(recipientIds, async (recipientId: number) => {
        const result = await recipientNotificationService.insert({
          NotificationId: notificationId,
          UserId: recipientId,
          RecordStatus: RecordStatus.Inserted,
        });
        if (!result.ok) {
          errorOccurred = true;
          errorMessage = result.error.Message;
        }
      });
      setIsProcessing(false);
      if (errorOccurred) {
        notification.error({
          message: t("USER_MODAL_ADD_USER_FAILURE"),
          description: errorMessage,
        });
      } else {
        notification.success({
          message: t("USER_MODAL_ADD_USER_SUCCESS"),
        });
      }
      await refreshRecipients();
      setIsVisible(false);
      setSeletedUsersIdsObject([]);
      setSelectedUsersIds([]);
    },
    []
  );

  return (
    <>
      <Modal
        title={
          <Heading
            title={t("USER_MODAL_SELECT_USER")}
            actionsClassName="Heading_Actions"
            actions={[
              <InputSearch
                key="search"
                className="Heading_Search"
                placeholder={t("SEARCH_PLACEHOLDER")}
                onSearch={onSearch}
              />,
            ]}
          />
        }
        open={isVisible}
        footer={
          <>
            <Button
              key="cancel"
              onClick={() => setIsVisible(false)}
              disabled={isProcessing}>
              {t("BUTTON_CANCEL")}
            </Button>
            <Button
              key="confirm"
              type="primary"
              onClick={() => insertRecipientInNotification(selectedUsersIds)}
              loading={isProcessing}
              disabled={selectedUsersIds.length === 0 || isProcessing}
            >
              {t("BUTTON_ADD")}
            </Button>
          </>
        }
        centered={true}
        wrapClassName="NotificationUsersModal"
        width="70%"
        onCancel={() => setIsVisible(false)}
        closable={!isProcessing}
      >
        <Spin spinning={isProcessing}>
          <Table<IUserModel>
            rowKey="Id"
            dataSource={userDataLoader.data?.Entities}
            columns={getUserColumns()}
            pagination={userTablePagination}
            loading={userDataLoader.loading}
            onChange={onTableChange}
            rowSelection={rowSelection}
            style={{ height: 500 }}
            scroll={{ y: 400 }}
          />
        </Spin>
      </Modal>
    </>
  );
};
