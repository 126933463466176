import {
  DeleteOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  AssetStreamsService,
  CreatorStreamState,
  ICreatorStreamModel,
  TimeHelper,
  useServiceCaller,
} from "@bms/common-services";
import {
  Button,
  Form,
  Link,
  Modal,
  PageContent,
  PageHeader,
  Result,
  SectionGrid,
  SectionGridItem,
  Spin,
  Text,
  useAppFeedback,
} from "@bms/common-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Player } from "../../../../components";
import { MediaChannelStateTag } from "../MediaChannelStateTag";
import { ROUTES as ASSET_ROUTES } from "../../../Asset/constants";

import "./MediaChannelForm.scss";

const assetStreamsService = new AssetStreamsService().promisify();

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 8 },
    lg: { span: 6 },
    xl: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 16 },
    lg: { span: 18 },
    xl: { span: 20 },
  },
};

export interface IMediaChannelFormProps {
  channel?: ICreatorStreamModel;
  loading: boolean;
  refresh?: () => Promise<void>;
  title: string;
  userId?: number;
  onBack?: (e: React.MouseEvent<HTMLElement> | undefined) => void;
}

export const MediaChannelForm: React.FC<IMediaChannelFormProps> = ({
  channel,
  loading,
  refresh,
  title,
  userId,
  onBack,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { notification } = useAppFeedback();

  const [showContentPreviewModal, setShowContentPreviewModal] = useState(false);
  const [previewContentUrl, setPreviewContentUrl] = useState("");

  const { modal } = useAppFeedback();

  const [deleteAsset] = useServiceCaller(async () => {
    if (userId) {
      const result = await assetStreamsService.delete(userId);

      if (result.ok) {
        notification.success({
          message: t("DELETE_CHANNEL_SUCCESS"),
        });

        if (onBack) {
          onBack(undefined);
        } else {
          refresh?.();
        }
      } else {
        notification.error({
          message: t("DELETE_CHANNEL_FAILURE"),
          description: result.error?.Message,
        });
      }
    }
  }, [userId]);

  const [startChannel, startChannelState] = useServiceCaller(async () => {
    if (userId) {
      const result = await assetStreamsService.start(userId);

      if (result.ok) {
        notification.success({
          message: t("START_CHANNEL_SUCCESS"),
        });
        refresh?.();
      } else {
        notification.error({
          message: t("START_CHANNEL_FAILURE"),
          description: result?.error?.Message,
        });
      }
    }
  }, [userId]);

  const [stopChannel, stopChannelState] = useServiceCaller(async () => {
    if (userId) {
      const result = await assetStreamsService.stop(userId);

      if (result.ok) {
        notification.success({
          message: t("STOP_CHANNEL_SUCCESS"),
        });
        refresh?.();
      } else {
        notification.error({
          message: t("STOP_CHANNEL_FAILURE"),
          description: result?.error?.Message,
        });
      }
    }
  }, [userId]);

  const onStartClick = () => {
    modal.confirm({
      title: t("START_CHANNEL_TITLE"),
      content: t("START_CHANNEL_CONFIRM_MESSAGE"),
      okText: t("COMMON_START"),
      cancelText: t("COMMON_CANCEL"),
      onOk: startChannel,
    });
  };

  const onStopClick = () => {
    modal.confirm({
      title: t("STOP_CHANNEL_TITLE"),
      content: t("STOP_CHANNEL_CONFIRM_MESSAGE"),
      okText: t("COMMON_STOP"),
      cancelText: t("COMMON_CANCEL"),
      onOk: stopChannel,
    });
  };

  const onDeleteClick = () => {
    modal.confirm({
      title: t("DELETE_CHANNEL_TITLE"),
      content: t("DELETE_CHANNEL_CONFIRM_MESSAGE"),
      okText: t("COMMON_DELETE"),
      cancelText: t("COMMON_CANCEL"),
      onOk: deleteAsset,
    });
  };

  const onOpenContentPreviewModal = (contentPreview: string) => {
    setPreviewContentUrl(contentPreview);
    setShowContentPreviewModal(true);
  };

  const onCancelContentPreviewModal = () => {
    setPreviewContentUrl("");
    setShowContentPreviewModal(false);
  };

  const isStreamChannelIdle =
    channel?.State === CreatorStreamState.Idle ||
    channel?.State === CreatorStreamState.Stopped;
  const isStreamChannelRunning = channel?.State === CreatorStreamState.Running;

  const globalLoading =
    loading || startChannelState.processing || stopChannelState.processing;

  const allowCrossSiteCredentials = channel?.Provider === "AWS";

  return (
    <PageContent className="MediaChannelForm">
      <Spin spinning={globalLoading}>
        <PageHeader
          title={title}
          onBack={onBack}
          extra={
            <>
              {isStreamChannelIdle && (
                <Button
                  icon={<PlayCircleOutlined />}
                  onClick={onStartClick}
                  title={t("BUTTON_START_TITLE")}
                >
                  {t("BUTTON_START_TITLE")}
                </Button>
              )}
              {isStreamChannelRunning && (
                <Button
                  icon={<StopOutlined />}
                  onClick={onStopClick}
                  title={t("BUTTON_STOP_TITLE")}
                >
                  {t("BUTTON_STOP_TITLE")}
                </Button>
              )}
              {userId && refresh && (
                <Button
                  shape="circle"
                  icon={<ReloadOutlined />}
                  onClick={refresh}
                  title={t("BUTTON_REFRESH_TITLE")}
                />
              )}
              {channel && (
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  title={t("DELETE_CHANNEL_BUTTON")}
                  onClick={onDeleteClick}
                  shape="circle"
                />
              )}
            </>
          }
        />
        {!channel && !loading && (
          <SectionGridItem>
            <Result
              status="error"
              title={t("MEDIA_CHANNEL_CHANNEL_NOT_FOUND_MESSAGE")}
              subTitle={t("MEDIA_CHANNEL_CHANNEL_NOT_FOUND_SUB_MESSAGE")}
              extra={[
                <Button key="go-back" onClick={history.goBack}>
                  {t("BUTTON_GO_BACK")}
                </Button>,
              ]}
            />
          </SectionGridItem>
        )}
        {channel && (
          <SectionGrid style={{ maxWidth: "1200px" }}>
            <SectionGridItem header={t("MEDIA_CHANNEL_FORM_CHANNEL")}>
              <Form {...formItemLayout}>
                <Form.Item label={t("MODEL_TYPE_CODE")}>
                  {channel?.Type}
                </Form.Item>
                <Form.Item label={t("MODEL_STATE")}>
                  <MediaChannelStateTag state={channel?.State} />
                </Form.Item>
                {channel.AssetId && (
                  <Form.Item
                    label={t("MEDIA_CHANNEL_COLUMN_ASSET_TITLE")}
                    key="Child"
                  >
                    <Link
                      to={`${ASSET_ROUTES.ASSET_DETAILS}/${channel.AssetId}`}
                    >
                      {channel.AssetTitle}
                    </Link>
                  </Form.Item>
                )}
                {channel.Created && (
                  <Form.Item label={t("MODEL_CREATION_DATE")}>
                    {TimeHelper.format(channel.Created)}
                  </Form.Item>
                )}
                {channel.CreatedByFullName && (
                  <Form.Item label={t("MODEL_CREATED_BY")}>
                    {channel.CreatedByFullName}
                  </Form.Item>
                )}
              </Form>
            </SectionGridItem>

            {channel?.InputEndpoints?.map((input, index) => (
              <SectionGridItem
                key={index}
                header={t("MEDIA_CHANNEL_FORM_INPUT")}
              >
                <Form {...formItemLayout}>
                  {Object.entries(input).map(([key, value]) => (
                    <Form.Item key={key} label={key}>
                      <Text copyable>{value}</Text>
                    </Form.Item>
                  ))}
                </Form>
              </SectionGridItem>
            ))}

            {channel?.OutputEndpoints.map((output, index) => (
              <SectionGridItem
                key={index}
                header={t("MEDIA_CHANNEL_FORM_OUTPUT")}
              >
                <Form {...formItemLayout}>
                  {Object.entries(output).map(([key, value]) => (
                    <Form.Item key={key} label={key}>
                      <Text copyable>{value}</Text>
                    </Form.Item>
                  ))}

                  <Form.Item label=" " colon={false}>
                    <Button
                      disabled={!isStreamChannelRunning}
                      icon={<PlayCircleOutlined />}
                      title={t("MEDIA_CHANNEL_FORM_PLAY")}
                      onClick={() => onOpenContentPreviewModal(output.Url)}
                    >
                      {t("MEDIA_CHANNEL_FORM_PLAY")}
                    </Button>
                  </Form.Item>
                </Form>

                {isStreamChannelRunning && (
                  <Player
                    contentUrl={output.Url}
                    contentType="application/x-mpegURL"
                    allowCrossSiteCredentials={allowCrossSiteCredentials}
                  />
                )}
              </SectionGridItem>
            ))}
          </SectionGrid>
        )}
      </Spin>
      <Modal
        open={showContentPreviewModal}
        footer={null}
        onCancel={onCancelContentPreviewModal}
        destroyOnClose
        preview
      >
        {showContentPreviewModal && (
          <Player
            contentUrl={previewContentUrl}
            contentType="application/x-mpegURL"
            allowCrossSiteCredentials={allowCrossSiteCredentials}
          />
        )}
      </Modal>
    </PageContent>
  );
};
