import { ComponentType, ScreenType } from "../../../enums";

export class ScreenTypeHelper {
  static getDescription(value?: ScreenType): string {
    switch (value) {
      case ScreenType.Custom:
        return "Custom";
      case ScreenType.ApplicationHeader:
        return "Application Header";
      case ScreenType.ApplicationMenu:
        return "Application Menu";
      case ScreenType.ApplicationFooter:
        return "Application Footer";
      case ScreenType.SettingsMenu:
        return "Settings Menu";
      case ScreenType.Home:
        return "Home";
      case ScreenType.Splash:
        return "Splash";
      case ScreenType.Login:
        return "Login";
      case ScreenType.Help:
        return "Help";
      case ScreenType.About:
        return "About";
      case ScreenType.MovieDetails:
        return "Movie Details";
      case ScreenType.SeriesDetails:
        return "Series Details";
      case ScreenType.LiveDetails:
        return "Live Details";
      case ScreenType.Profile:
        return "Profile";
      case ScreenType.UserOrders:
        return "User Orders";
      case ScreenType.Consents:
        return "Consents";
      case ScreenType.Preferences:
        return "Preferences";
      case ScreenType.Social:
        return "Social";
      case ScreenType.Messages:
        return "Messages";
      case ScreenType.Settings:
        return "Settings";
      case ScreenType.Search:
        return "Search";
      case ScreenType.Downloaded:
        return "Downloaded";
      case ScreenType.Notifications:
        return "Notifications";
      case ScreenType.Events:
        return "Events";
      case ScreenType.Friends:
        return "Friends";
      case ScreenType.Community:
        return "Community";
      case ScreenType.Explore:
        return "Explore";
      case ScreenType.CreatorProfiles:
        return "Creator Profiles";
      case ScreenType.Intro:
        return "Intro";
      case ScreenType.Info:
        return "Info";
      default:
        return `${value}`;
    }
  }

  static getValue(value: string | undefined): ScreenType | undefined {
    switch (value) {
      case "CUSTOM":
        return ScreenType.Custom;
      case "APPLICATION_HEADER":
        return ScreenType.ApplicationHeader;
      case "APPLICATION_MENU":
        return ScreenType.ApplicationMenu;
      case "APPLICATION_FOOTER":
        return ScreenType.ApplicationFooter;
      case "SETTINGS_MENU":
        return ScreenType.SettingsMenu;
      case "HOME":
        return ScreenType.Home;
      case "SPLASH":
        return ScreenType.Splash;
      case "LOGIN":
        return ScreenType.Login;
      case "HELP":
        return ScreenType.Help;
      case "ABOUT":
        return ScreenType.About;
      case "MOVIE_DETAILS":
        return ScreenType.MovieDetails;
      case "SERIES_DETAILS":
        return ScreenType.SeriesDetails;
      case "LIVE_DETAILS":
        return ScreenType.LiveDetails;
      case "PROFILE":
        return ScreenType.Profile;
      case "USER_ORDERS":
        return ScreenType.UserOrders;
      case "CONSENTS":
        return ScreenType.Consents;
      case "PREFERENCES":
        return ScreenType.Preferences;
      case "SOCIAL":
        return ScreenType.Social;
      case "MESSAGES":
        return ScreenType.Messages;
      case "SETTINGS":
        return ScreenType.Settings;
      case "SEARCH":
        return ScreenType.Search;
      case "NOTIFICATIONS":
        return ScreenType.Notifications;
      case "EVENTS":
        return ScreenType.Events;
      case "FRIENDS":
        return ScreenType.Friends;
      case "COMMUNITY":
        return ScreenType.Community;
      case "EXPLORE":
        return ScreenType.Explore;
      case "CREATOR_PROFILES":
        return ScreenType.CreatorProfiles;
      case "DOWNLOADED":
        return ScreenType.Downloaded;
      case "INTRO":
        return ScreenType.Intro;
      case "INFO":
        return ScreenType.Info;
      case "REGISTER":
        return ScreenType.Register;
      case "REGISTER_SUCCESS":
        return ScreenType.RegisterSuccess;
      default:
        return undefined;
    }
  }

  static getStringValue(value?: ScreenType): string {
    switch (value) {
      case ScreenType.Custom:
        return "CUSTOM";
      case ScreenType.ApplicationHeader:
        return "APPLICATION_HEADER";
      case ScreenType.ApplicationMenu:
        return "APPLICATION_MENU";
      case ScreenType.ApplicationFooter:
        return "APPLICATION_FOOTER";
      case ScreenType.SettingsMenu:
        return "SETTINGS_MENU";
      case ScreenType.Home:
        return "HOME";
      case ScreenType.Splash:
        return "SPLASH";
      case ScreenType.Login:
        return "LOGIN";
      case ScreenType.Help:
        return "HELP";
      case ScreenType.About:
        return "ABOUT";
      case ScreenType.MovieDetails:
        return "MOVIE_DETAILS";
      case ScreenType.SeriesDetails:
        return "SERIES_DETAILS";
      case ScreenType.LiveDetails:
        return "LIVE_DETAILS";
      case ScreenType.Profile:
        return "PROFILE";
      case ScreenType.UserOrders:
        return "USER_ORDERS";
      case ScreenType.Consents:
        return "CONSENTS";
      case ScreenType.Preferences:
        return "PREFERENCES";
      case ScreenType.Social:
        return "SOCIAL";
      case ScreenType.Messages:
        return "MESSAGES";
      case ScreenType.Settings:
        return "SETTINGS";
      case ScreenType.Search:
        return "SEARCH";
      case ScreenType.Notifications:
        return "NOTIFICATIONS";
      case ScreenType.Events:
        return "EVENTS";
      case ScreenType.Friends:
        return "FRIENDS";
      case ScreenType.Community:
        return "COMMUNITY";
      case ScreenType.Explore:
        return "EXPLORE";
      case ScreenType.CreatorProfiles:
        return "CREATOR_PROFILES";
      case ScreenType.Downloaded:
        return "DOWNLOADED";
      case ScreenType.Intro:
        return "INTRO";
      case ScreenType.Info:
        return "INFO";
      case ScreenType.Register:
        return "REGISTER";
      case ScreenType.RegisterSuccess:
        return "REGISTER_SUCCESS";
      default:
        return "";
    }
  }
  static getColor(value?: ScreenType): string {
    let color: string;

    switch (value) {
      case ScreenType.Custom:
        color = "#954";
        break;
      case ScreenType.ApplicationHeader:
        color = "#393";
        break;
      case ScreenType.ApplicationMenu:
        color = "#2d77ff";
        break;
      case ScreenType.ApplicationFooter:
        color = "#2d7f7f";
        break;
      case ScreenType.SettingsMenu:
        color = "#5C469C";
        break;
      case ScreenType.Home:
        color = "#bf7c2a";
        break;
      case ScreenType.Splash:
        color = "#949";
        break;
      case ScreenType.Login:
        color = "#8b43ff";
        break;
      case ScreenType.Help:
        color = "#b33";
        break;
      case ScreenType.About:
        color = "#D0EA2B";
        break;
      case ScreenType.MovieDetails:
        color = "#FD5308";
        break;
      case ScreenType.SeriesDetails:
        color = "#0391CE";
        break;
      case ScreenType.LiveDetails:
        color = "#8C8C8C";
        break;
      case ScreenType.Register:
        color = "#A7194B";
        break;
      case ScreenType.RegisterSuccess:
        color = "#DCE775";
        break;
      case ScreenType.Intro:
        color = "#0D47A1";
        break;
      case ScreenType.Profile:
        color = "#617A55";
        break;
      case ScreenType.UserOrders:
        color = "#9E6F21";
        break;
      case ScreenType.Consents:
        color = "#088395";
        break;
      case ScreenType.Preferences:
        color = "#FF8400";
        break;
      case ScreenType.Info:
        color = "#7A3E3E";
        break;
      case ScreenType.Search:
        color = "#A832A2";
        break;
      default:
        color = "#555";
        break;
    }

    return color;
  }

  // Commented components currently don't have a support
  static getAvailableComponents(screenType: ScreenType): ComponentType[] {
    switch (screenType) {
      case ScreenType.ApplicationHeader:
        return [ComponentType.ApplicationHeaderItem];
      case ScreenType.ApplicationMenu:
        return [ComponentType.ApplicationMenuItem];
      case ScreenType.ApplicationFooter:
        return [ComponentType.ApplicationFooterItem];
      case ScreenType.SettingsMenu:
        return [ComponentType.SettingsMenuItem];
      case ScreenType.Custom:
        return [
          ComponentType.Epg,
          ComponentType.WebView,
          ComponentType.List,
          ComponentType.SectionMenu,
          ComponentType.TextWidget,
        ];
      case ScreenType.Splash:
      case ScreenType.Login:
      case ScreenType.About:
      case ScreenType.Info:
      case ScreenType.Profile:
      case ScreenType.UserOrders:
      case ScreenType.Consents:
      case ScreenType.Preferences:
      case ScreenType.Settings:
      case ScreenType.Search:
        return [];
      case ScreenType.Home:
        return [
          ComponentType.List,
          ComponentType.SectionMenu,
          ComponentType.TextWidget,
        ];
      case ScreenType.Profile:
        return [ComponentType.List];
      case ScreenType.Intro:
        return [
          ComponentType.List,
          ComponentType.TextWidget,];
      case ScreenType.Help:
        return [
          ComponentType.WebView,
          ComponentType.TextWidget,
        ];
      default:
        return [ComponentType.List];
    }
  }

  static instanceable(screenTypeCode?: string): boolean {
    switch (screenTypeCode) {
      case ScreenType.Custom:
      case ScreenType.ApplicationHeader:
      case ScreenType.ApplicationMenu:
      case ScreenType.ApplicationFooter:
      case ScreenType.SettingsMenu:
      case ScreenType.Home:
      case ScreenType.Intro:
      case ScreenType.Register:
      case ScreenType.RegisterSuccess:
      case ScreenType.Help:
      case ScreenType.Search:
        return true;
      default:
        return false;
    }
  }

  static configurable(screenTypeCode?: string): boolean {
    switch (screenTypeCode) {
      case ScreenType.Custom:
      case ScreenType.ApplicationHeader:
      case ScreenType.ApplicationMenu:
      case ScreenType.ApplicationFooter:
      case ScreenType.SettingsMenu:
      case ScreenType.Home:
      case ScreenType.Intro:
      case ScreenType.Help:
        return true;
      default:
        return false;
    }
  }

  static parametrizable(screenTypeCode?: string): boolean {
    switch (screenTypeCode) {
      case ScreenType.ApplicationFooter:
      case ScreenType.Intro:
      case ScreenType.Register:
      case ScreenType.RegisterSuccess:
      case ScreenType.Search:
      case ScreenType.Custom:
        return true;
      default:
        return false;
    }
  }
}
