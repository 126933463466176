import {
  CaretRightOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  DollarOutlined,
  ForwardOutlined,
  GiftOutlined,
  TagsOutlined,
  UserSwitchOutlined,
  VideoCameraOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  AreaChartOutlined,
  WalletOutlined,
  NotificationOutlined,
  FundViewOutlined,
  StarFilled,
  UnorderedListOutlined,
  LineChartOutlined,
  StarOutlined,
  ExperimentOutlined,
  CloudServerOutlined,
  SnippetsOutlined,
  ReadOutlined,
  ProjectOutlined,
  TranslationOutlined,
} from "@ant-design/icons";
import {
  ApplicationConfigurationModule,
  TranslationsModule,
  AssetModule,
  DictionariesModule,
  PaymentModule,
  ProductModule,
  UserModule,
  MediaChannelModule,
  ROUTES,
  NotificationModule,
  RatingModule,
  TestingModule,
} from "@bms/common-cms";
import {
  Divider,
  Icon,
  Menu,
  MenuItem,
  MenuItemClickEvent,
  Sider,
  SubMenu,
  useAppFeedback,
} from "@bms/common-ui";
import cx from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { BooleanHelper, useConfig } from "@bms/common-services";
import { useLocalStorage } from "../../helpers/useLocalStorage";
import {
  ArrowLeft,
  ArrowRight,
  Layouts,
  NotificationRecipients,
  Settings,
  Users,
  Video,
} from "../../resources/icons";
import "./Sidebar.scss";

interface IMenuItemType {
  label?: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  key: React.Key;
  accept?: string[];
  children?: IMenuItemType[];
}

const featureFlags = {
  showMediaChannels: BooleanHelper.toBool(
    process.env.REACT_APP_SHOW_MEDIA_CHANNELS,
    false
  ),
  showChannelsList: BooleanHelper.toBool(
    process.env.REACT_APP_SHOW_CHANNELS_LIST,
    false
  ),
  showCryptoCoinPrices: BooleanHelper.toBool(
    process.env.REACT_APP_SHOW_CRYPTO_COIN_PRICES,
    false
  ),
  showNotReadyModules: BooleanHelper.toBool(
    process.env.REACT_APP_SHOW_NOT_READY_MODULES,
    false
  ),
};

export function Sidebar() {
  const [collapsed, setCollapsed] = useLocalStorage<boolean>({
    key: "side-menu-state",
  });
  const [submenus, setSubmenus] = useLocalStorage<string[]>({
    key: "side-menu-submenus",
  });

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { config } = useConfig();
  const { notification } = useAppFeedback();

  const isCollapsed = collapsed ?? false;

  const toggle = useCallback(() => setCollapsed(isCollapsed ? null : true), [
    isCollapsed,
    setCollapsed,
  ]);

  const menuOnClick = useCallback(
    ({ key }: MenuItemClickEvent) => {
      const keyString = key.toString();
      if (keyString.startsWith("todo")) {
        notification.warning({
          message: "Not supported yet",
          description: "Full menu support coming soon",
        });
      } else {
        push(keyString);
      }
    },
    [push, notification]
  );

  const menuItems: IMenuItemType[] = useMemo(() => {
    const menu: IMenuItemType[] = [
      {
        key: ROUTES.DASHBOARD,
        icon: <HomeOutlined />,
        label: t("MENU_DASHBOARD"),
      },
    ];

    const menuItemCMS = {
      key: "CMS",
      icon: <Icon component={Video} />,
      label: t("MENU_CMS"),
      children: [
        {
          key: AssetModule.ROUTES.ASSET_LIST,
          icon: <CaretRightOutlined />,
          label: t("MENU_CMS_MEDIA"),
          children:
            process.env.REACT_APP_SHOW_ADDITIONAL_ASSET_LIST === "true"
              ? [
                {
                  key: `${AssetModule.ROUTES.ASSET_LIST}`,
                  icon: null,
                  label: t("MENU_CMS_MEDIA_COLLECTIONS_ALL", "All"),
                  accept: [AssetModule.ROUTES.ASSET_DETAILS],
                },
                {
                  key: `${AssetModule.ROUTES.ASSET_LIST}/upcoming`,
                  icon: null,
                  label: t("MENU_CMS_MEDIA_COLLECTIONS_UPCOMING", "Upcoming"),
                },
                {
                  key: `${AssetModule.ROUTES.ASSET_LIST}/past`,
                  icon: null,
                  label: t("MENU_CMS_MEDIA_COLLECTIONS_PAST", "Past"),
                },
                {
                  key: `${AssetModule.ROUTES.ASSET_LIST}/article`,
                  icon: null,
                  label: t("MENU_CMS_MEDIA_COLLECTIONS_ARTICLE", "Articles"),
                },
              ]
              : undefined,
        },
        {
          key: AssetModule.ROUTES.ASSET_COLLECTION_LIST,
          icon: <ForwardOutlined />,
          label: t("MENU_CMS_MEDIA_COLLECTIONS"),
          accept: [AssetModule.ROUTES.ASSET_COLLECTION_DETAILS],
        },
        {
          key: AssetModule.ROUTES.ASSET_CATEGORIES,
          icon: <TagsOutlined />,
          label: t("MENU_CMS_MEDIA_CATEGORIES"),
        },
        {
          key: AssetModule.ROUTES.ASSET_IMPRESSIONS,
          icon: <FundViewOutlined />,
          label: t("MENU_CMS_ASSET_IMPRESSIONS"),
        },
      ],
    };

    if (featureFlags.showMediaChannels) {
      menuItemCMS.children.push({
        key: MediaChannelModule.ROUTES.CHANNEL_LIST,
        icon: <VideoCameraOutlined />,
        label: t("MENU_CMS_MEDIA_CHANNELS"),
        accept: [MediaChannelModule.ROUTES.CHANNEL_DETAILS],
      });
    }

    if (featureFlags.showChannelsList) {
      menuItemCMS.children.push({
        key: `${AssetModule.ROUTES.ASSET_LIST}/channel`,
        icon: <MenuUnfoldOutlined />,
        label: t("MENU_CMS_MEDIA_CHANNELS_LIST"),
      });
    }

    menu.push(menuItemCMS);

    const menuItemCRM = {
      key: "CRM",
      icon: <UserSwitchOutlined />,
      label: t("MENU_CRM"),
      children: [
        {
          key: UserModule.ROUTES.CUSTOMER_LIST,
          icon: <ContactsOutlined />,
          label: t("MENU_CRM_CUSTOMERS"),
          accept: [UserModule.ROUTES.CUSTOMER_DETAILS],
        },
        {
          key: ProductModule.ROUTES.PRODUCTS_LIST,
          icon: <GiftOutlined />,
          label: t("MENU_CRM_PRODUCTS"),
          accept: [ProductModule.ROUTES.PRODUCTS_DETAILS],
        },
        {
          key: AssetModule.ROUTES.ASSET_PRICE_LIST,
          icon: <DollarOutlined />,
          label: t("MENU_CRM_PRICES"),
          accept: [AssetModule.ROUTES.ASSET_PRICE_DETAILS],
        },
        {
          key: PaymentModule.ROUTES.PAYMENT_LIST,
          icon: <CreditCardOutlined />,
          label: t("MENU_CRM_PAYMENTS"),
          accept: [PaymentModule.ROUTES.PAYMENT_DETAILS],
        },
        {
          key: PaymentModule.ROUTES.SALES_REVENUE,
          icon: <AreaChartOutlined />,
          label: t("MENU_CRM_SALES_REVENUE"),
          accept: [PaymentModule.ROUTES.SALES_REVENUE],
        },
      ],
    };

    menuItemCRM.children.push({
      key: NotificationModule.ROUTES.NOTIFICATION_LIST,
      icon: <NotificationOutlined />,
      label: t("MENU_NOTIFICATIONS"),
      accept: [NotificationModule.ROUTES.NOTIFICATION_LIST],
    });
    menuItemCRM.children.push({
      key: NotificationModule.ROUTES.NOTIFICATION_RECIPIENTS,
      icon: <Icon component={NotificationRecipients} />,
      label: t("MENU_NOTIFICATION_RECIPIENTS"),
      accept: [NotificationModule.ROUTES.NOTIFICATION_RECIPIENTS],
    });

    if (featureFlags.showCryptoCoinPrices) {
      menuItemCRM.children.push({
        key: PaymentModule.ROUTES.CRYPTO_COIN_PRICES,
        icon: <WalletOutlined />,
        label: t("MENU_CRM_CRYPTO_COIN_PRICES"),
        accept: [PaymentModule.ROUTES.CRYPTO_COIN_PRICES],
      });
    }

    menu.push(menuItemCRM);

    menu.push({
      key: ApplicationConfigurationModule.ROUTES.CONFIGURATION_LIST,
      icon: <Icon component={Layouts} />,
      label: t("MENU_STUDIO"),
      accept: [
        ApplicationConfigurationModule.ROUTES.CONFIGURATION_DETAILS,
        ApplicationConfigurationModule.ROUTES.CONFIGURATION_SCREEN_DETAILS,
      ],
    });

    menu.push({
      key: TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_LIST,
      icon: <TranslationOutlined />,
      label: t("CUSTOM_TRANSLATIONS"),
      accept: [TranslationsModule.ROUTES.CONFIGURATION_TRANSLATION_LIST],
    });

    if (config?.Rating?.Enabled) {
      menu.push({
        key: "RATING",
        icon: <StarFilled />,
        label: t("RATING_MODULE_TITLE"),
        children: [
          {
            key: RatingModule.ROUTES.RATING_CATEGORIES,
            icon: <UnorderedListOutlined />,
            label: t("RATING_CATEGORIES"),
            accept: [RatingModule.ROUTES.RATING_CATEGORIES],
          },
          {
            key: RatingModule.ROUTES.RATING_LIST,
            icon: <StarOutlined />,
            label: t("RATING_APP_RATING_LIST"),
            accept: [RatingModule.ROUTES.RATING_LIST],
          },
          {
            key: RatingModule.ROUTES.APP_RATING,
            icon: <LineChartOutlined />,
            label: t("RATING_APP_RATING_ANALYTICS"),
            accept: [RatingModule.ROUTES.APP_RATING],
          },
        ],
      });
    }

    menu.push({
      key: "ADMINISTRATION_GROUP",
      icon: <Icon component={Settings} />,
      label: t("MENU_ADMINISTRATION"),
      children: [
        {
          key: UserModule.ROUTES.ADMINISTRATOR_LIST,
          icon: <Icon component={Users} />,
          label: t("MENU_ADMINISTRATION_USERS"),
          accept: [UserModule.ROUTES.ADMINISTRATOR_DETAILS],
        },
        {
          key: "DICTIONARIES_GROUP",
          icon: <Icon type="read" />,
          label: t("MENU_ADMINISTRATION_DICTIONARIES"),
          children: [
            {
              key:
                DictionariesModule.ROUTES.DICTIONARY_VIDEO_AGE_RESTRICTION_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_AGE_RESTRICTIONS"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_APPLICATION_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_APPLICATIONS"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_MEDIA_TYPE_LIST,
              label: t("DICTIONARY_ASSET_TYPE_TITLE"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_CONSENT_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_CONSENTS"),
            },
            {
              key:
                ApplicationConfigurationModule.ROUTES
                  .CONFIGURATION_COMPONENT_TYPES_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_COMPONENT_TYPES"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_COUNTRIES_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_COUNTRIES"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_CURRENCY_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_CURRIENCIES"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_LANGUAGE_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_LANGUAGES"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_PEOPLE,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_PEOPLE"),
            },
            {
              key: DictionariesModule.ROUTES.DICTIONARY_PLATFORM_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_PLATFORMS"),
            },
            {
              key:
                DictionariesModule.ROUTES
                  .DICTIONARY_ASSET_PURCHASE_PERIOD_TYPE_LIST,
              label: t(
                "MENU_ADMINISTRATION_DICTIONARIES_PURCHASE_PERIOD_TYPES"
              ),
            },
            {
              key:
                ApplicationConfigurationModule.ROUTES
                  .CONFIGURATION_SCREEN_TYPES_LIST,
              label: t("MENU_ADMINISTRATION_DICTIONARIES_SCREEN_TYPES"),
            },
          ],
        },
      ],
    });

    if (featureFlags.showNotReadyModules) {
      menu.push({
        key: "TESTING",
        icon: <ExperimentOutlined />,
        label: t("TESTING_MODULE_TITLE"),
        children: [
          {
            key: TestingModule.ROUTES.DEVICES,
            icon: <CloudServerOutlined />,
            label: t("TESTING_DEVICES"),
            accept: [TestingModule.ROUTES.DEVICES],
          },
          {
            key: TestingModule.ROUTES.APPLICATIONS,
            icon: <SnippetsOutlined />,
            label: t("TESTING_APPLICATIONS"),
            accept: [
              TestingModule.ROUTES.APPLICATIONS,
              TestingModule.ROUTES.APPLICATION_DETAILS,
            ],
          },
          {
            key: TestingModule.ROUTES.TEST_CASES,
            icon: <ReadOutlined />,
            label: t("TESTING_TEST_CASES"),
            accept: [TestingModule.ROUTES.TEST_CASES],
          },
          {
            key: TestingModule.ROUTES.TEST_DEFINITIONS,
            icon: <ProjectOutlined />,
            label: t("TESTING_TEST_DEFINITIONS"),
            accept: [
              TestingModule.ROUTES.TEST_DEFINITIONS,
              TestingModule.ROUTES.TEST_DEFINITION_DETAILS,
            ],
          },
        ],
      });
    }

    return menu;
  }, [t, config]);

  const mapNode = (item: IMenuItemType | null) => {
    if (!item) return null;

    return item.children ? (
      <SubMenu
        key={item.key}
        title={
          <span>
            {item.icon}
            <span>{item.label}</span>
          </span>
        }
      >
        {item.children.map((child) => mapNode(child))}
      </SubMenu>
    ) : (
      <MenuItem key={item.key}>
        {item.icon}
        <span>{item.label}</span>
      </MenuItem>
    );
  };

  const reduceToHighlightedKey = (
    found: string | undefined,
    node: IMenuItemType | null
  ): string | undefined => {
    if (found) {
      return found;
    }
    if (!node) {
      return undefined;
    }
    if (node.key === pathname) {
      return node.key;
    }
    if (node.accept?.some((v) => pathname.startsWith(v))) {
      return `${node.key}`;
    }
    if (node.children) {
      return node.children.reduce(reduceToHighlightedKey, undefined);
    }
    return undefined;
  };

  const renderIcon = () => (collapsed ? <ArrowRight /> : <ArrowLeft />);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      trigger={null}
      width={270}
      className={cx("sidebar", { "sidebar-collapsed": collapsed })}
    >
      <Menu
        mode="inline"
        selectedKeys={[
          menuItems.reduce(reduceToHighlightedKey, undefined) ?? "",
        ]}
        onClick={menuOnClick}
        openKeys={submenus}
        onOpenChange={(openSubmenus) => setSubmenus(openSubmenus as string[])}
      >
        {menuItems.map(mapNode).filter((e) => e != null)}
      </Menu>
      <div className="sidebar-footer">
        <Divider />
        <div onClick={toggle} className="sidebar-trigger-container">
          <Icon component={renderIcon} />
        </div>
      </div>
    </Sider>
  );
}
