import {
  Col,
  Form,
  IFormInstance,
  IFormValues,
  Input,
  REGEX,
  Row,
} from "@bms/common-ui";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ApplicationCustomPropertyModel } from "../../models";
import {
  ComponentPropertiesBase,
  IComponentPropertiesBaseProps,
  IComponentPropertiesBaseState,
} from "../ComponentPropertiesBase";

import "./ApplicationCustomProperties.scss";

export interface ApplicationCustomPropertiesStateProps {}

export interface ApplicationCustomPropertiesDispatchProps {}

export interface ApplicationCustomPropertiesOwnProps {}

export interface ApplicationCustomPropertiesProps
  extends ApplicationCustomPropertiesStateProps,
    ApplicationCustomPropertiesDispatchProps,
    ApplicationCustomPropertiesOwnProps,
    IComponentPropertiesBaseProps,
    WithTranslation {}

export interface ApplicationCustomPropertiesState
  extends IComponentPropertiesBaseState {}

export class ApplicationCustomProperties extends ComponentPropertiesBase<
  ApplicationCustomPropertiesProps,
  ApplicationCustomPropertiesState,
  ApplicationCustomPropertyModel
> {
  formRef = React.createRef<IFormInstance>();

  getPropertiesModel(): ApplicationCustomPropertyModel {
    const { component } = this.props;
    const properties = new ApplicationCustomPropertyModel();
    properties.init(component);

    return properties;
  }

  public onFinish = (values: IFormValues) => {
    const { component } = this.props;

    if (!component) {
      return;
    }

    component.Name = values.Name;
  };

  public onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const sanitized = e.target.value.replace(REGEX.ROUTE_NAME, "");
    this.formRef?.current?.setFieldsValue({
      RouteName: sanitized,
    });
    this.properties.RouteName = sanitized;
    this.onPropertyChange("RouteName");
  };

  public renderRouteNameProperty = () => {
    const { t } = this.props;

    return (
      <Row gutter={8}>
        <Col flex="auto">
          <Form.Item
            name="RouteName"
            label={t("CONFIGURATION_PROPERTY__ROUTE_NAME")}
            initialValue={this.properties.RouteName}
          >
            <Input
              name="RouteName"
              placeholder={"greatest-hits"}
              onChange={this.onInputChange}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  public render() {
    const formItemLayout = {
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 16 },
        lg: { span: 20 },
      },
    };

    return (
      <Form
        ref={this.formRef}
        name="ApplicationCustomProperties"
        {...formItemLayout}
        onFinish={this.onFinish}
      >
        <Row direction="column" justify="space-between" className="full-height">
          <Col>{this.renderRouteNameProperty()}</Col>
        </Row>
      </Form>
    );
  }
}
