import { Observable, Observer } from "rxjs";
import { HTTP_METHOD } from "../../constants";
import { IUserConsentModel } from "../../models/User";
import { AxiosSubscriber } from "../AxiosSubscriber";

export class UserConsentsService {
  get url(): string {
    return "/UsersConsents";
  }

  public select = (): Observable<IUserConsentModel[]> =>
    new Observable(
      (observer: Observer<IUserConsentModel[]>) =>
        new AxiosSubscriber(observer, {
          method: HTTP_METHOD.POST,
          url: `${this.url}/Select`,
        })
    );

  public update = (data: IUserConsentModel): Observable<IUserConsentModel> =>
    new Observable(
      (observer: Observer<IUserConsentModel>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/Update`,
        })
    );

  public updateCollection = (
    data: IUserConsentModel[]
  ): Observable<IUserConsentModel[]> =>
    new Observable(
      (observer: Observer<IUserConsentModel[]>) =>
        new AxiosSubscriber(observer, {
          data,
          method: HTTP_METHOD.PUT,
          url: `${this.url}/UpdateCollection`,
        })
    );

  public selectByUser = (userid: number): Observable<IUserConsentModel[]> =>
    new Observable(
      (observer: Observer<IUserConsentModel[]>) =>
        new AxiosSubscriber(observer, {
          axiosConfig: {
            params: {
              userid,
            },
          },
          method: HTTP_METHOD.POST,
          url: `${this.url}/SelectByUser`,
        })
    );
}
