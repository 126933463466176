export const REGEX = {
  POSITIVE_INTEGER: /^\d+$/,
  PASSWORD: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#€?()×÷=/_<>\\\[\]'":;{}|~£¥•+!@$%^&*-.,`]).{8,}$/,
  PHONE: /^\+(?:[0-9] ?){1,}[0-9]$/,
  URL: /^(https?):\/\/[^\s/$.?#].[^\s]*$/,
  FULL_NAME: /^\s*\S.{1,148}\S\s*$/,
  COUNTRYCODE: /^\+/,
  IP: /^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
  ROUTE_NAME: /[^a-zA-Z0-9-_]/g,
};
